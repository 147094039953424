@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* https://icons.getbootstrap.com/ */
.green-icon {
    fill: green;
}
.red-icon {
    fill: red;
}
.bi {
    width: 30px;
    height: 30px;
}
.half-bi {
    width: 24px;
    height: 24px;
}

.preloader{
    background-color: white;
    width: 100%;
    height: 200px;
}
.preloader img{
    width: 100px;
    display: block;
    margin: auto;
}

.tabs-dropdown-menu {
    position: absolute;
    top: 100%;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    /*border: 1px solid rgba(0, 0, 0, .15);*/
    /*border-radius: .25rem;*/
    /*border-top-left-radius: 0.25rem;*/
    /*border-top-right-radius: 0.25rem;*/
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .175);
}

.dropdown-menu-left {
    left: 0;
    right: auto;
}

.tabs-dropdown-menu.show {
    display: block;
}

.date-dropdown-menu {
    height: 241px;
    left: 0;
}

.status-info-holder .status-info-step1.status-info-step {
    padding: .125rem 1rem;
}

.select-document-set .link-set.link-set.status-red {
    background-color: #FEE;
}

.dropdown-menu .dropdown-current, .dropdown-menu .dropdown-current:active {
    /*color: #fff;*/
    text-decoration: none;
    background-color: #f8f9fa;
}

/* scroll bug - instead of menu list is showed*/
/*.loader-holder {*/
/*    top: 70px;*/
/*}*/

.more-options-drop {
    position: relative;
}

.nav-tabs .nav-link.nav-link-disabled::before {
    /*transition: transform .3s linear;*/
    /*background: #1777ec;*/
    /*content: "";*/
    /*position: absolute;*/
    /*left: 0;*/
    /*bottom: 0;*/
    /*width: 100%;*/
    height: 0;
}

.nav-tabs .nav-link.nav-link-disabled {
    color: #9ea0a5;
}

.status-info-admission .status-info-holder{
    font-size: .75rem;
    width: 100%;
    /*padding: 0.125rem 0.15rem;*/
}

.status-info-admission a:not(.btn) {
    color: inherit;
    text-decoration: underline;
}

.no-documents {
    color: white;
}

.loader-holder {
    pointer-events: all;
}

.header .d-flex {
    z-index: 1000;
}

.sorting, .sorting_asc, .sorting_desc {
    cursor: pointer;
}

.search-active .search-form:before {
    transition: transform .3s linear;
    background: #1777ec;
    content: "";
    position: absolute;
    left: 0;
    bottom: -17px;
    width: 100%;
    height: .125rem;
    transform: translateX(0%);
}

.modal .modal-dialog.modal-wide {
    max-width: 92.25rem;
    min-width: 1100px;
}
.modal-wide .heading {
    margin: 20px;
}

/* Important part */
.modal-dialog{
    overflow-y: initial !important
}
.modal-content{
    height: 700px;
    overflow-y: auto;
    overflow-x: auto;
}
.oec-selector, .pointer {
    cursor: pointer;
}
.oec-selector:hover {
    background-color: #ececec;
}

.oec-selector-selected {
    background-color: #ececec;
}

.table-oec.table.table-sm td {
    padding: .3rem;
    font-family: "Roboto", sans-serif;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1;
    color: black;
    text-align: left;
}
.table-oec.table.table-sm {
    border: solid 1px black;
}
.white-space-no-wrap {
    white-space: nowrap;
}

.table-oec.table th, .table-oec.table td {
    border-top: 1px solid black;
    border-left: 1px solid #cccccc;
}

.oec-description {
    line-height: 1;
    color: black;
}

.oec-print {
    padding: 50px;
}

.status-info-step.status-scheduled, .status-scheduled {
    background: #ff9a24;
}
.status-info-step.status-scheduled .cover-line, .status-scheduled .cover-line {
    fill: #ff9a24;
    stroke: #ff9a24;
}

.status-info-step.status-error, .status-error {
    background: #dc3545;
}
.status-info-step.status-error .cover-line, .status-error .cover-line {
    fill: #dc3545;
    stroke: #dc3545;
}

.status-info-hold .status-info-step1 {
    max-width: 9.25rem;
}

.funding-box {
    cursor: pointer;
}

.search-form input[type="search"] {
    padding-left: 0.5rem;
}
.search-form button.btn-search {
    background: #1777ec;
    min-width: 0;
    color: white;
    margin-left: 5px;
}

.procedure-list-table tr:hover {
    background-color: #eee;
}

.documents-status.n-a .icon-check {
    visibility: visible;
}

.info-tooltip.icon-check {
    margin-left: 0;
}

.react-toast-notifications__toast .react-toast-notifications__toast__content {
    font-family: "Roboto", sans-serif;
    color: black;
}

.react-toast-notifications__toast.react-toast-notifications__toast--success .react-toast-notifications__toast__icon-wrapper {
    background-color: #10c17d;
}
.react-toast-notifications__toast.react-toast-notifications__toast--error .react-toast-notifications__toast__content{
    color: #721c24;
}
.react-toast-notifications__toast.react-toast-notifications__toast--error .react-toast-notifications__toast__icon-wrapper {
    background-color: #721c24;
}
.modal-content-fit.modal-content {
    height: 100%;
}

.dropdown-item.disabled {
    opacity: .65;
}

.ql-editor{
    min-height:200px;
}

.modal-content-large {
    width: 700px;
}

.ql-tooltip {
    left: 0 !important;
}
.ql-container {
    height: 300px;
}
.height-100 {
    height: 100%;
}
.height-90 {
    height: 90%;
}
.nav-tabs {
    z-index: 10;
}
.search-holder .search-form {
    z-index: 15;
}
.ta-center {
    text-align: center;
}
.container {
    max-width: 1672px;
}
.filter .icon.ico-small-down {
    margin-left: 10px;
}
.filter .dropdown-menu {
    padding: .5rem 0;
}
.display-none {
    display: none;
}
.medical-history-signed-at-block {
    margin-top: -20px;
    padding-left: 32px;
}

.modal-admission-info-content {
    margin-left: 30px
}

.mai-table-title {
    font-weight: bold;
    font-size: 1rem;
}
.modal-admission-info-content table { border-collapse: colla; width: 100%}
.modal-admission-info-content tr { border: none; }
.modal-admission-info-content td {
    border-right: solid 1px #000;
    border-left: solid 1px #000;
    padding: 5px;
}

.modal-admission-info-content td:last-child {
    border-right: none;
    border-left: solid 1px #000;
    width: 40%;
}
.modal-admission-info-content td:first-child {
    border-right: solid 1px #000;
    border-left: none;
    width: 150px;
}

.mai-green {
    padding: 3px 10px;
    background-color: #87FAA1;
}
.mai-yellow {
    padding: 3px 10px;
    background-color: yellow;
}

.mai-none {
    padding: 3px 10px;
}

.modal-med-history.modal-single-column .modal-header {
    padding: .5rem 2rem .5rem 2.1rem;
}

.search-holder .search-form {
    top: -1.75rem;
}
.table tbody td.lifehouse-adm-list-btn {
    padding: 0;
}
.table tbody td.lifehouse-adm-list-btn button {
    margin: 0;
}

.subfilter-dropdown-menu {
    width: 150px;
    /*height: 205px;*/
    left: 18px;
    top: 38px;
    padding: 10px;
}
.flex-break {
    flex-basis: 100%
}
.subfilter-dropdown-menu .table-buttons-item .btn {
    font-size: .6875rem;
    color: #fff;
    line-height: 1;
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.subfilter-dropdown-menu .table-buttons-holder {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0 -.1875rem;
    padding: 0;
    border-top: none;
    text-transform: uppercase;
}

.scroll-600 {
    overflow-y: auto;
    height: 600px;
}
.procedure-list-table thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 6;
    background-color: #fff;
}
.lifehouse-button-blue, .lifehouse-button-blue:hover, .lifehouse-button-blue:focus {
    background-color: #1777ec;
    color: white;
}


.lifehouse-button-orange, .lifehouse-button-orange:hover, .lifehouse-button-orange:focus {
    background-color: #fd8800;
    color: white;
}
.uppercase {
    text-transform: uppercase;
}

.badge-custom-grey {
    color: #212529;
    background-color: #dae0e5;
}

.large-btn {
    width: 6.5rem;
}
/* https://www.w3schools.com/colors/colors_groups.asp */
.btn-pink svg{
    color: #FF69B4;
}
.btn-pink{
    background-color: #FFC0CB;
}
.btn-pink:hover{
    background-color: #FFB6C1 ;
}
.lifehouse-color-code {
    border: 1px solid;
    box-shadow: none;
    margin: 0 0.125rem !important;
    color: #3e3f42;
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    height: 10px;
    text-transform: uppercase;

    border-radius: 0.2rem;
}
.lifehouse-color-code.yellow {
    background-color: #ffbb6d;
    border-color: #ff9a24;
}
.lifehouse-color-code.pink {
    background-color: #FFC0CB;
    border-color: #FF69B4;
}
.lifehouse-color-code.grey {
    background-color: lightgrey;
    border-color: grey;
}
.lifehouse-color-code.purple {
    background-color: purple;
    border-color: mediumpurple;
}
.lifehouse-color-code.red {
    background-color: red;
    border-color: darkred;
}

.filepond--pdf-preview-wrapper {
    margin-top: 30px;
}
.lifehouseDateHolder {
    position: relative;
}
.lifehouseDateFlag {
    position: absolute;
    font-size: 30px;
    top: -7px;
    color: red;
    right: 0px;
}
.top-pager {
    float: right;
    position: absolute;
    right: 1px;
    top: 1px;
}
.page-dots {
    padding: 5px;
}
.modal-dev-details {
    margin-left: 20px;
}

.loader-holder.loader-opacity {
    opacity: 0.7;
}
.btn-sm2 {
    padding: 0.375rem 0.75rem;
    font-size: .75rem;
    line-height: 0.8;
    border-radius: 0.2rem;
}
.activity-icon.grey {
    color: #9ea0a5;
}

.date-mask-field input, .date-mask-field input:focus-visible, .date-mask-field input:focus {
    border: none;
    outline: none;
    color: #3e3f42;
}

.form-title2 {
    text-align: right;
    display: flex;
    justify-content: right;
    align-items: center;
}
.form-error {
    border-color: #f63b2c;
}
.error {
    color: #f63b2c;
}
.patient-message ul.check-list li {
    margin-right: 10px;
}
.patient-message textarea {
    width: 500px;
    height: 250px;
}
.dropdown-toggle:after {
    margin-left: 4pt;
}

.lifehouse-adm-list-btn.red-border button.btn-group-table-button.btn {
    border: solid red;
}
.btn-lifehouse-pac {
    background-color: #ffaaff;
    color: white;
}
/*.btn-lifehouse-pac span.ico-checkmark {*/
/*    color: #10c17d !important*/
/*}*/
.btn-lifehouse-psn {
    background-color: #12b6b8;
    color: white;
}
/*.btn-lifehouse-psn span.ico-checkmark {*/
/*    color: #10c17d !important*/
/*}*/
.modal-content.modal-height-200 {
    height: 200px;
}
.modal-content.modal-height-400 {
    height: 400px;
}
.modal-content.modal-height-450 {
    height: 450px;
}
.btn-label-red {
    color: red;
    font-weight: bold;
}
.btn-border {
    border: solid 1px #12b6b8;
}
.lifehouse-comments textarea {
    height: 100px;
    min-height: 100px;
}
.lifehouse-comments select {
    margin-right: 10px;
}
.btn-25px {
    height: 25px;
}
.lifehouse-button-green {
    background-color: #10c17d !important;
    color: white !important;
}
.lifehouse-button-red {
    background-color: red !important;
    color: white !important;
}
.container1072 {
    width: 1072px;
}
.modal-content {
    max-height: calc(100vh - 50px);
}
.procedure-list-table .btn-sm.pad046 {
    padding: 0.46rem 0.625rem;
}

.procedure-list-table .red-border .btn{
    border: solid red;
}
.send-reg-btn {
    color: white;
    padding-left: 1.375rem;
    padding-right: 1.375rem;
}
.alerts-list-item .alert-text {
    width: 300px;
}
.diff-grey-bg {
    background: #eeeeee;
}
.modal-sidebar {
    overflow: auto;
    max-height: 700px;
    max-width: 370px;
}

.btn-dark-green {
    background-color: #448161 !important;
    color: white;
}
/*.remove-payment-option {*/
/*    background: lightgrey;*/
/*}*/

.jmph-filters {
    width: 560px;
}

.table thead .sorting.custom-ui-sort-header .title {
    white-space: break-spaces;
}
.btn-with-badge-counter {
    position: relative;
}
.badge-counter {
    padding: 0px 5px;
    background-color: #10c17d;
    position: absolute;
    top: -10px;
    right: -10px;
    border: 1px solid #10c17d;
    border-radius: 50%;
    color: white;
}

.btn-with-badge-counter .icon.ico-checkmark {
    margin-right: 0;
}

.yellow {
    padding: 3px 10px;
    background-color: yellow;
}

.flex-badges {
    align-items: start;
}
.input-group {
    position: relative;
  }

.input-group .btn {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  border: none;
  background: none;
}

.input-group .form-control {
  padding-right: 40px;
}

.input-group .btn:hover,
.input-group .btn:focus {
  box-shadow: none;
  background: none;
}
.password-requirements-box {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 1rem;
  background-color: #f8f9fa;
}

.password-requirements-box ul li {
    display: flex;
    align-items: center;
}

.password-requirements-box svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
}
.input-group {
    position: relative;
  }

.input-group .btn {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  border: none;
  background: none;
}

.input-group .form-control {
  padding-right: 40px;
}

.input-group .btn:hover,
.input-group .btn:focus {
  box-shadow: none;
  background: none;
}
.password-requirements-box {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 1rem;
  background-color: #f8f9fa;
}

.password-requirements-box ul li {
    display: flex;
    align-items: center;
}

.password-requirements-box svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
}
.ignored-comment {
    text-decoration: line-through;
    color: red;
}

.more-options-drop.btn.lifehouse-comments-more {
    padding: 0;
}

.lifehouse-comments-more.more-options-drop .dropdown-toggle {
    padding: 0;
}