@font-face {
	font-family: "icomoon";
	src: url("../fonts/icomoon.ttf?bnnajp") format("truetype"), url("../fonts/icomoon.woff?bnnajp") format("woff"), url("../fonts/icomoon.svg?bnnajp#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.icon {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "icomoon" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1; /* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ico-exclamation-circle:before {
	content: "\e915";
}

.ico-right-arrow-caret:before {
	content: "\e912";
}

.ico-left-arrow-caret:before {
	content: "\e913";
}

.ico-more:before {
	content: "\e911";
}

.ico-zoom:before {
	content: "\e900";
}

.ico-user:before {
	content: "\e901";
}

.ico-document-with-conner:before {
	content: "\e902";
}

.ico-small-down:before {
	content: "\e903";
}

.ico-s-add:before {
	content: "\e904";
}

.ico-phone:before {
	content: "\e905";
}

.ico-letter:before {
	content: "\e906";
}

.ico-gear:before {
	content: "\e907";
}

.ico-eye:before {
	content: "\e908";
}

.ico-expand-h:before {
	content: "\e909";
}

.ico-e-remove:before {
	content: "\e90a";
}

.ico-document:before {
	content: "\e90b";
}

.ico-c-info:before {
	content: "\e90c";
}

.ico-c-check:before {
	content: "\e90d";
}

.ico-arrow-left:before {
	content: "\e90e";
}

.ico-add-user:before {
	content: "\e90f";
}

.ico-a-chat:before {
	content: "\e910";
}

.ico-checkmark:before {
	content: "\e914";
}

.ico-tick:before {
	content: "\e914";
}

.ico-correct:before {
	content: "\e914";
}

.ico-accept:before {
	content: "\e914";
}

.ico-ok:before {
	content: "\e914";
}

.clearfix:after {
	content: "";
	display: block;
	clear: both;
}

.ellipsis {
	white-space: nowrap; /* 1 */
	text-overflow: ellipsis; /* 2 */
	overflow: hidden;
}

html,
body {
	height: 100%;
}

body {
	min-width: 1572px;
}

h2,
.h2 {
	margin-bottom: .8em;
}

#wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: relative;
}

.main {
	padding-top: 2.1875rem;
	padding-bottom: 1.875rem;
}

.nav-indicator,
.main-nav a,
.nav-tabs .nav-link {
	display: block;
	position: relative;
	overflow: hidden;
}

.nav-indicator:before,
.main-nav a:before,
.nav-tabs .nav-link:before {
	-webkit-transition: -webkit-transform .3s linear;
	transition: -webkit-transform .3s linear;
	transition: transform .3s linear;
	transition: transform .3s linear, -webkit-transform .3s linear;
	background: #1777ec;
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: .125rem;
	-webkit-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	transform: translateX(-100%);
}

.nav-indicator-active:before,
.main-nav li.active a:before,
.main-nav a:hover:before,
.nav-tabs .nav-link.active:before,
.nav-tabs .nav-link:hover:before {
	-webkit-transform: translateX(0%);
	-ms-transform: translateX(0%);
	transform: translateX(0%);
}

.heading .icon {
	opacity: .5;
	margin: 0 .625rem .125rem 0;
}

.heading .ico-arrow-left {
	font-size: .875rem;
}

.heading-info {
	margin-top: -.25rem;
}

.heading-info .btns .btn {
	margin-left: .3125rem;
}

.heading-info .btn {
	padding: .625rem .9375rem;
}

.main-content h2 {
	margin-bottom: .6em;
}

.header {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	padding: .9375rem .625rem;
}

.logo {
	font-size: 16px;
	line-height: 1.5;
	font-weight: 500;
}

.header .logo {
	margin-right: 1.5625rem;
	margin-left: .75rem;
}

.logo a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: inherit;
}

.logo .txt {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-left: .6875rem;
}

.main-nav {
	margin: -1.5625rem 0 -1.5rem;
	position: relative;
	overflow: hidden;
}

.main-nav .main-nav-list {
	list-style: none;
	padding: 0;
	margin: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.main-nav li {
	padding: 0 1rem 0 1.0625rem;
	font-weight: 500;
}

.main-nav a {
	color: #6e6f72;
	padding-top: 1.5625rem;
	padding-bottom: 1.5rem;
}

.login-area-block {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: auto;
}

.login-block {
	background: red;
}

.login-block .heading {
	color: #9ea0a5;
	font-size: 16px;
}

.login-block h1 {
	color: #3e3f42;
}

.login-form .form-group {
	padding-top: .875rem;
}

.login-form label {
	display: block;
	color: #9ea0a5;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: bold;
}

.login-form .input-row {
	position: relative;
}

.login-form .input-row.active-field .show-password {
	color: #1777ec;
}

.login-form .show-password {
	position: absolute;
	top: 50%;
	right: .625rem;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #9ea0a5;
}

.login-form .show-password:hover {
	color: #1777ec;
}

.login-form input[type=password] {
	padding-right: 40px;
}

.login-form .btn {
	width: 100%;
}

.loader-holder {
	-webkit-transition: opacity .3s linear;
	transition: opacity .3s linear;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100vw;
	z-index: 100;
	overflow: hidden;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	opacity: 1;
	pointer-events: none;
	visibility: visible;
}

.loaded .loader-holder {
	opacity: 0;
	visibility: hidden;
}

.btn-loader {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: .125rem .625rem .125rem .625rem;
	padding: .9375rem;
}

.btn {
	white-space: nowrap;
	min-width: 3rem;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.btn .icon:not(:only-child) {
	margin-right: .375rem;
}

.btn-white {
	color: #3e3f42;
	background-color: #fff;
	border-color: #d8dce6;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075);
}

.btn-white:hover {
	color: #212529;
	background-color: #ececec;
	border-color: #b9c0d2;
}

.btn-white:focus,
.btn-white.focus {
	color: #212529;
	background-color: #ececec;
	border-color: #b9c0d2;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075), 0 0 0 .2rem rgba(189, 193, 202, .5);
}

.btn-white.disabled,
.btn-white:disabled {
	color: #212529;
	background-color: #fff;
	border-color: #d8dce6;
}

.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
	color: #212529;
	background-color: #e6e5e5;
	border-color: #b1b9cd;
}

.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(189, 193, 202, .5);
}

.btn-white-primary {
	color: #1777ec;
	background-color: #fff;
	border-color: #d8dce6;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075);
}

.btn-white-primary:hover {
	color: #212529;
	background-color: #ececec;
	border-color: #b9c0d2;
}

.btn-white-primary:focus,
.btn-white-primary.focus {
	color: #212529;
	background-color: #ececec;
	border-color: #b9c0d2;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075), 0 0 0 .2rem rgba(189, 193, 202, .5);
}

.btn-white-primary.disabled,
.btn-white-primary:disabled {
	color: #212529;
	background-color: #fff;
	border-color: #d8dce6;
}

.btn-white-primary:not(:disabled):not(.disabled):active,
.btn-white-primary:not(:disabled):not(.disabled).active,
.show > .btn-white-primary.dropdown-toggle {
	color: #212529;
	background-color: #e6e5e5;
	border-color: #b1b9cd;
}

.btn-white-primary:not(:disabled):not(.disabled):active:focus,
.btn-white-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-white-primary.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(189, 193, 202, .5);
}

.btn-warning {
	color: #fff;
	background-color: #ff9a24;
	border-color: #ff9a24;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075);
}

.btn-warning:hover {
	color: #212529;
	background-color: #fd8800;
	border-color: #f08100;
}

.btn-warning:focus,
.btn-warning.focus {
	color: #212529;
	background-color: #fd8800;
	border-color: #f08100;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075), 0 0 0 .2rem rgba(222, 136, 37, .5);
}

.btn-warning.disabled,
.btn-warning:disabled {
	color: #212529;
	background-color: #ff9a24;
	border-color: #ff9a24;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
	color: #fff;
	background-color: #f08100;
	border-color: #e37a00;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(222, 136, 37, .5);
}

.btn-warning:hover {
	color: #fff;
}

.btn-time-opener {
	margin: -.5rem 0 .9375rem;
}

.btn-time-opener .icon-calendar {
	opacity: .8;
	margin: -.125rem .6875rem 0 0;
}

.btn-time-opener .ico-small-down {
	font-size: .5rem;
	color: #cecfd2;
	margin: .25rem -.375rem 0 1.75rem !important;
}

.btn-add-user {
	font-size: .75rem;
	line-height: 1.5;
	padding: .625rem 1rem;
}

.dropdown-toggle:after {
	border: none;
	font-family: "icomoon" !important;
	content: "\e903";
	color: #cecfd2;
	font-size: .5rem;
	vertical-align: middle;
}

.search-form .input-row {
	position: relative;
}

/*.search-form .ico-zoom {*/
/*	position: absolute;*/
/*	top: 50%;*/
/*	left: .8125rem;*/
/*	-webkit-transform: translateY(-50%);*/
/*	-ms-transform: translateY(-50%);*/
/*	transform: translateY(-50%);*/
/*	font-size: 1.125rem;*/
/*}*/

.search-form input[type=search] {
	padding-left: 2.25rem;
}

.person-info-drop {
	margin-left: 1.5rem;
}

.person-info-drop .avatar {
	width: 2rem;
	height: 2rem;
	border-radius: 100%;
}

.person-info-drop .dropdown-toggle {
	border: none;
	background: none;
	width: 2rem;
	height: 2rem;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: .3125rem;
	margin-left: .25rem;
}

.person-info-drop .dropdown-menu {
	font-size: .875rem;
}

.hospital-info-drop {
	margin-left: 1.5rem;
}

.hospital-info-drop .dropdown-toggle:after {
	margin-left: .625rem;
}

/*
 * This combined file was created by the DataTables downloader builder:
 *   https://datatables.net/download
 *
 * To rebuild or modify this file with the latest versions of the included
 * software please visit:
 *   https://datatables.net/download/#dt/jq-3.3.1/dt-1.10.20
 *
 * Included libraries:
 *   jQuery 3 3.3.1, DataTables 1.10.20
 */

/*
 * Table styles
 */

table.dataTable {
	width: 100%;
	margin: 0 auto;
	clear: both;
	border-collapse: separate;
	border-spacing: 0; /*
   * Header and footer styles
   */ /*
   * Body styles
   */
}

table.dataTable thead th,
table.dataTable tfoot th {
	font-weight: bold;
}

table.dataTable thead th,
table.dataTable thead td {
	padding: 10px 18px;
}

table.dataTable thead th:active,
table.dataTable thead td:active {
	outline: none;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
	padding: 10px 18px 6px 18px;
	border-top: 1px solid #111;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
	cursor: pointer;
	*cursor: hand;
	background-repeat: no-repeat;
	background-position: center right;
}

table.dataTable tbody tr {
	background-color: #fff;
}

table.dataTable tbody tr.selected {
	background-color: #b0bed9;
}

table.dataTable tbody th,
table.dataTable tbody td {
	padding: 8px 10px;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
	border-top: 1px solid #ddd;
}

table.dataTable.row-border tbody tr:first-child th,
table.dataTable.row-border tbody tr:first-child td,
table.dataTable.display tbody tr:first-child th,
table.dataTable.display tbody tr:first-child td {
	border-top: none;
}

table.dataTable.cell-border tbody th,
table.dataTable.cell-border tbody td {
	border-top: 1px solid #ddd;
	border-right: 1px solid #ddd;
}

table.dataTable.cell-border tbody tr th:first-child,
table.dataTable.cell-border tbody tr td:first-child {
	border-left: 1px solid #ddd;
}

table.dataTable.cell-border tbody tr:first-child th,
table.dataTable.cell-border tbody tr:first-child td {
	border-top: none;
}

table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
	background-color: #f9f9f9;
}

table.dataTable.stripe tbody tr.odd.selected,
table.dataTable.display tbody tr.odd.selected {
	background-color: #acbad4;
}

table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
	background-color: #f6f6f6;
}

table.dataTable.hover tbody tr:hover.selected,
table.dataTable.display tbody tr:hover.selected {
	background-color: #aab7d1;
}

table.dataTable.order-column tbody tr > .sorting_1,
table.dataTable.order-column tbody tr > .sorting_2,
table.dataTable.order-column tbody tr > .sorting_3,
table.dataTable.display tbody tr > .sorting_1,
table.dataTable.display tbody tr > .sorting_2,
table.dataTable.display tbody tr > .sorting_3 {
	background-color: #fafafa;
}

table.dataTable.order-column tbody tr.selected > .sorting_1,
table.dataTable.order-column tbody tr.selected > .sorting_2,
table.dataTable.order-column tbody tr.selected > .sorting_3,
table.dataTable.display tbody tr.selected > .sorting_1,
table.dataTable.display tbody tr.selected > .sorting_2,
table.dataTable.display tbody tr.selected > .sorting_3 {
	background-color: #acbad5;
}

table.dataTable.display tbody tr.odd > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
	background-color: #f1f1f1;
}

table.dataTable.display tbody tr.odd > .sorting_2,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
	background-color: #f3f3f3;
}

table.dataTable.display tbody tr.odd > .sorting_3,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
	background-color: whitesmoke;
}

table.dataTable.display tbody tr.odd.selected > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
	background-color: #a6b4cd;
}

table.dataTable.display tbody tr.odd.selected > .sorting_2,
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
	background-color: #a8b5cf;
}

table.dataTable.display tbody tr.odd.selected > .sorting_3,
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
	background-color: #a9b7d1;
}

table.dataTable.display tbody tr.even > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
	background-color: #fafafa;
}

table.dataTable.display tbody tr.even > .sorting_2,
table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
	background-color: #fcfcfc;
}

table.dataTable.display tbody tr.even > .sorting_3,
table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
	background-color: #fefefe;
}

table.dataTable.display tbody tr.even.selected > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
	background-color: #acbad5;
}

table.dataTable.display tbody tr.even.selected > .sorting_2,
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
	background-color: #aebcd6;
}

table.dataTable.display tbody tr.even.selected > .sorting_3,
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
	background-color: #afbdd8;
}

table.dataTable.display tbody tr:hover > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
	background-color: #eaeaea;
}

table.dataTable.display tbody tr:hover > .sorting_2,
table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
	background-color: #ececec;
}

table.dataTable.display tbody tr:hover > .sorting_3,
table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
	background-color: #efefef;
}

table.dataTable.display tbody tr:hover.selected > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
	background-color: #a2aec7;
}

table.dataTable.display tbody tr:hover.selected > .sorting_2,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
	background-color: #a3b0c9;
}

table.dataTable.display tbody tr:hover.selected > .sorting_3,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
	background-color: #a5b2cb;
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
	white-space: nowrap;
}

table.dataTable.compact thead th,
table.dataTable.compact thead td {
	padding: 4px 17px 4px 4px;
}

table.dataTable.compact tfoot th,
table.dataTable.compact tfoot td {
	padding: 4px;
}

table.dataTable.compact tbody th,
table.dataTable.compact tbody td {
	padding: 4px;
}

table.dataTable th.dt-left,
table.dataTable td.dt-left {
	text-align: left;
}

table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
	text-align: center;
}

table.dataTable th.dt-right,
table.dataTable td.dt-right {
	text-align: right;
}

table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
	text-align: justify;
}

table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
	white-space: nowrap;
}

table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
	text-align: left;
}

table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
	text-align: center;
}

table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
	text-align: right;
}

table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
	text-align: justify;
}

table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
	white-space: nowrap;
}

table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
	text-align: left;
}

table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
	text-align: center;
}

table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
	text-align: right;
}

table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
	text-align: justify;
}

table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
	white-space: nowrap;
}

table.dataTable,
table.dataTable th,
table.dataTable td {
	box-sizing: content-box;
}

/*
 * Control feature layout
 */

.dataTables_wrapper {
	position: relative;
	clear: both;
	*zoom: 1;
	zoom: 1;
}

.dataTables_wrapper .dataTables_length {
	float: left;
}

.dataTables_wrapper .dataTables_filter {
	float: right;
	text-align: right;
}

.dataTables_wrapper .dataTables_filter input {
	margin-left: .5em;
}

.dataTables_wrapper .dataTables_info {
	clear: both;
	float: left;
	padding-top: .755em;
}

.dataTables_wrapper .dataTables_paginate {
	float: right;
	text-align: right;
	padding-top: .25em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
	box-sizing: border-box;
	display: inline-block;
	min-width: 1.5em;
	padding: .5em 1em;
	margin-left: 2px;
	text-align: center;
	text-decoration: none !important;
	cursor: pointer;
	*cursor: hand;
	color: #333 !important;
	border: 1px solid transparent;
	border-radius: 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
	color: #333 !important;
	border: 1px solid #979797;
	background-color: white;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #dcdcdc)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, white 0%, #dcdcdc 100%); /* Chrome10+,Safari5.1+ */ /* FF3.6+ */ /* IE10+ */ /* Opera 11.10+ */
	background: linear-gradient(to bottom, white 0%, #dcdcdc 100%); /* W3C */
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
	cursor: default;
	color: #666 !important;
	border: 1px solid transparent;
	background: transparent;
	box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
	color: white !important;
	border: 1px solid #111;
	background-color: #585858;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #585858 0%, #111 100%); /* Chrome10+,Safari5.1+ */ /* FF3.6+ */ /* IE10+ */ /* Opera 11.10+ */
	background: linear-gradient(to bottom, #585858 0%, #111 100%); /* W3C */
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
	outline: none;
	background-color: #2b2b2b;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%); /* Chrome10+,Safari5.1+ */ /* FF3.6+ */ /* IE10+ */ /* Opera 11.10+ */
	background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%); /* W3C */
	box-shadow: inset 0 0 3px #111;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
	padding: 0 1em;
}

.dataTables_wrapper .dataTables_processing {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 40px;
	margin-left: -50%;
	margin-top: -25px;
	padding-top: 20px;
	text-align: center;
	font-size: 1.2em;
	background-color: white;
	background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, .9)), color-stop(75%, rgba(255, 255, 255, .9)), color-stop(100%, rgba(255, 255, 255, 0)));
	background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .9) 25%, rgba(255, 255, 255, .9) 75%, rgba(255, 255, 255, 0) 100%);
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .9) 25%, rgba(255, 255, 255, .9) 75%, rgba(255, 255, 255, 0) 100%);
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
	color: #333;
}

.dataTables_wrapper .dataTables_scroll {
	clear: both;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
	*margin-top: -1px;
	-webkit-overflow-scrolling: touch;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
	vertical-align: middle;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {
	height: 0;
	overflow: hidden;
	margin: 0 !important;
	padding: 0 !important;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
	border-bottom: 1px solid #111;
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
.dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
	border-bottom: none;
}

.dataTables_wrapper:after {
	visibility: hidden;
	display: block;
	content: "";
	clear: both;
	height: 0;
}

.search-holder {
	padding-right: 2rem;
}

.search-holder .search-form {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	z-index: 5;
	margin-left: auto;
	margin-top: -3.5625rem;
	margin-bottom: 1rem;
	max-width: 12.8125rem;
}

.title {
	font-size: .6875rem;
	color: #9ea0a5;
	display: inline-block;
	text-transform: uppercase;
	font-weight: 700;
}

.table {
	color: #3e3f42;
}

.table,
table.dataTable {
	width: calc(100% - 2px);
}

.table thead th,
table.dataTable thead th {
	border-bottom-width: 0;
}

.table thead .sorting .title,
.table thead .sorting_asc .title,
.table thead .sorting_desc .title,
.table thead .sorting_asc_disabled .title,
.table thead .sorting_desc_disabled .title,
table.dataTable thead .sorting .title,
table.dataTable thead .sorting_asc .title,
table.dataTable thead .sorting_desc .title,
table.dataTable thead .sorting_asc_disabled .title,
table.dataTable thead .sorting_desc_disabled .title {
	white-space: nowrap;
	padding-right: 1.25rem;
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position: top right;
}

.table thead .sorting .title,
table.dataTable thead .sorting .title {
	background-image: url("../images/sort_both.png");
}

.table thead .sorting_asc .title,
table.dataTable thead .sorting_asc .title {
	background-image: url("../images/sort_asc.png");
}

.table thead .sorting_desc .title,
table.dataTable thead .sorting_desc .title {
	background-image: url("../images/sort_desc.png");
}

.table thead .sorting_asc_disabled .title,
table.dataTable thead .sorting_asc_disabled .title {
	background-image: url("../images/sort_asc_disabled.png");
}

.table thead .sorting_desc_disabled .title,
table.dataTable thead .sorting_desc_disabled .title {
	background-image: url("../images/sort_desc_disabled.png");
}

.table thead th,
.table thead td,
.table tbody th,
.table tbody td,
table.dataTable thead th,
table.dataTable thead td,
table.dataTable tbody th,
table.dataTable tbody td {
	vertical-align: middle;
	padding-left: .5rem;
	padding-right: .5rem;
}

.table thead th:first-child,
.table thead td:first-child,
.table tbody th:first-child,
.table tbody td:first-child,
table.dataTable thead th:first-child,
table.dataTable thead td:first-child,
table.dataTable tbody th:first-child,
table.dataTable tbody td:first-child {
	padding-left: 1rem;
}

.table thead th:last-child,
.table thead td:last-child,
.table tbody th:last-child,
.table tbody td:last-child,
table.dataTable thead th:last-child,
table.dataTable thead td:last-child,
table.dataTable tbody th:last-child,
table.dataTable tbody td:last-child {
	padding-right: 2rem;
}

.table thead th,
.table tbody th,
table.dataTable thead th,
table.dataTable tbody th {
	padding-top: .6875rem;
	padding-bottom: .6875rem;
}

.table thead td,
.table tbody td,
table.dataTable thead td,
table.dataTable tbody td {
	padding-top: .9375rem;
	padding-bottom: .9375rem;
}

.table h2,
.table .h2,
.table h3,
.table .h3,
table.dataTable h2,
table.dataTable .h2,
table.dataTable h3,
table.dataTable .h3 {
	margin-bottom: 0;
	padding-top: .875rem;
	padding-bottom: .875rem;
}

.settings-table,
.procedure-list-table {
	font-size: .75rem;
	font-weight: 500;
}

.settings-table th:last-child,
.settings-table td:last-child,
.procedure-list-table th:last-child,
.procedure-list-table td:last-child {
	width: 10%;
}

.settings-table a:not(.btn),
.procedure-list-table a:not(.btn) {
	color: inherit;
	text-decoration: underline;
}

.settings-table a:not(.btn):hover,
.procedure-list-table a:not(.btn):hover {
	text-decoration: none;
}

.settings-table .btns-hold .btn,
.procedure-list-table .btns-hold .btn {
	margin: 0 .1875rem;
}

.procedure-list-table {
	line-height: 1.5;
}

.procedure-list-table th:first-child,
.procedure-list-table td:first-child {
	width: 1% !important;
}

.procedure-list-table .btn-sm {
	text-transform: uppercase;
	font-size: .6875rem;
	padding: .3125rem .625rem;
}

table.dataTable.procedure-list-table tbody td {
	padding-top: .25rem;
	padding-bottom: .25rem;
}

.settings .btn {
	color: #6e6f72;
	width: 2rem;
	height: 2rem;
	padding: .375rem .125rem;
	min-width: 32px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.settings .btn .icon {
	font-size: 1rem;
}

.patient-name {
	font-size: .75rem;
	font-weight: 500;
}

.procedure {
	display: block;
	font-weight: normal;
	color: #9ea0a5;
}

.info-tooltip {
	font-size: 1rem;
	color: #cecfd2;
	margin-left: .75rem;
}

.select-document-set {
	width: 100%;
}

.select-document-set .link-set.link-set {
	background: #fff;
	border: 2px dashed #d8dce6;
	color: #3e3f42;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: .6875rem;
	text-transform: uppercase;
	text-decoration: none;
	min-height: 64px;
	font-weight: bold;
}

.select-document-set .link-set.link-set:hover {
	color: #1777ec;
	border-color: #1777ec;
}

.select-document-set .link-set.link-set i {
	font-size: .9375rem;
	margin-right: .625rem;
	opacity: .5;
}

.documents-status {
	color: #cecfd2;
	position: relative;
	margin-right: 1.5rem;
}

.documents-status.done .icon-check {
	visibility: visible;
}

.documents-status .doc {
	font-size: 1.4375rem;
}

.documents-status .icon-check {
	position: absolute;
	top: .875rem;
	left: .5625rem;
	visibility: hidden;
	font-size: 1rem;
}

.more-options-drop .dropdown-toggle {
	color: #9ea0a5;
	border: none;
	background: none;
	padding: .3125rem .125rem;
	font-size: 1.25rem;
}

.more-options-drop .dropdown-toggle:after {
	display: none;
}

.more-options-drop .dropdown-toggle:focus {
	outline: none;
}

.more-options-drop .dropdown-menu {
	font-size: .75rem;
}

.table-buttons-holder {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	list-style-type: none;
	margin: 0 -.1875rem;
	padding: .5rem 0 .5rem 1rem;
	border-top: 1px solid #eaedf3;
	text-transform: uppercase;
}

.table-buttons-item {
	margin: 0 .1875rem;
}

.table-buttons-item .btn {
	font-size: .6875rem;
	color: #fff;
	line-height: 1;
	padding-top: .25rem;
	padding-bottom: .25rem;
}

.btn-group-table {
	margin: 0 -.125rem;
}

.btn-group-table-button {
	border: none;
	box-shadow: none;
	margin: 0 .125rem !important;
	color: #3e3f42;
	padding-left: .375rem;
	padding-right: .375rem;
	height: 43px;
	text-transform: uppercase;
}

/*.btn-group-table-button:first-child {*/
/*	width: 62px;*/
/*}*/

.btn-group-table-button .icon {
	font-size: .75rem;
}

.custom-table-radio-row {
	margin-right: 1.5rem;
}

.custom-table-radio-row label {
	color: #6e6f72;
	margin-bottom: 0;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
}

.custom-table-radio-row label:first-of-type {
	border-radius: 4px 0 0 4px !important;
}

.custom-table-radio-row input:checked + label {
	background-color: #1777ec;
	color: #fff;
	z-index: 1;
}

.nav-tabs {
	position: relative;
	z-index: 5;
	padding-left: 2rem;
	margin-bottom: -.0625rem;
}

.nav-tabs .nav-item {
	padding-right: 1.5625rem;
}

.nav-tabs .nav-link {
	color: #6e6f72;
	font-weight: 500;
	padding: 1.25rem 0 1.1875rem;
	line-height: 1.3125rem;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
	color: #3e3f42;
}

.procedure-list-tabs .nav-tabs .nav-link.active,
.procedure-list-tabs .nav-tabs .nav-link:hover {
	color: #9ea0a5;
}

.procedure-list-tabs .nav-tabs .nav-link.active span,
.procedure-list-tabs .nav-tabs .nav-link:hover span {
	color: #3e3f42;
}

.procedure-list-tabs .nav-link {
	color: #9ea0a5;
	padding: 1.6875rem 0 1.5625rem;
}

.procedure-list-tabs .nav-link span {
	font-size: 1.125rem;
	line-height: 1.3125rem;
	color: #6e6f72;
}

.procedure-list-tabs .nav-link .ico-small-down {
	font-size: .5rem;
}

.procedure-list-tabs .nav-link.active,
.procedure-list-tabs .nav-link:hover {
	color: #9ea0a5;
}

.procedure-list-tabs .nav-link.active span,
.procedure-list-tabs .nav-link:hover span {
	color: #3e3f42;
}

.person-activity-tabs {
	padding: 0 1.4375rem;
}

.tab-pane-holder {
	border-top: 1px solid #eaedf3;
	padding: 1.3125rem 1.4375rem;
	margin-top: -.0625rem;
}

.tab-pane-holder .heading {
	overflow: hidden;
}

.tab-pane-holder h3 {
	position: relative;
}

.tab-pane-holder h3:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	border-top: 1px solid #eaedf3;
}

.tab-pane-holder h3 span {
	position: relative;
	display: inline-block;
	padding-right: .75rem;
	background: #fff;
}

.btn-group-text-sm {
	font-size: .5625rem;
	color: #3e3f42;
	font-weight: 700;
}

.procedure-list-tabs-sm {
	padding-left: 1rem;
}

.procedure-list-tabs-sm .nav-item {
	padding-right: 3.375rem;
}

.procedure-list-tabs-sm .nav-link {
	padding-top: 1.375rem;
	padding-bottom: .6875rem;
}

.status-info-holder {
	width: 29.25rem;
	height: 4rem;
	margin-right: 1.5rem;
	overflow: hidden;
}

.status-info-holder .status-info-step {
	width: calc(33.333% - 8px);
}

.status-info-holder .status-info-step3 {
	width: calc(33.333% + 16px);
}

.status-info-step {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
	padding: .125rem 1.25rem;
}

.status-info-step.status-done,
.status-info-step.status-pending {
	color: #fff;
	text-align: left;
}

.status-info-step.status-done {
	background: #10c17d;
}

.status-info-step.status-done .cover-line {
	fill: #10c17d;
	stroke: #10c17d;
}

.status-info-step.status-pending {
	background: #1777ec;
}

.status-info-step.status-pending .cover-line {
	fill: #1777ec;
	stroke: #1777ec;
}

.status-info-step.status-info-step1 {
	z-index: 5;
}

.status-info-step.status-info-step2,
.status-info-step.status-info-step3 {
	padding-left: 2.1875rem;
}

.status-info-step.status-info-step2 {
	z-index: 4;
}

.status-info-step.status-info-step3 {
	z-index: 3;
}

.status-info-step .arrow-shape {
	visibility: hidden;
	position: absolute;
	width: 1.5rem;
	top: -4px;
	height: 110%;
	right: -1.5rem;
}

.status-info-step .cover-line {
	fill: #eff0f1;
	stroke: #eff0f1;
}

.status-info-step.status-info-step1 .arrow-shape,
.status-info-step.status-info-step2 .arrow-shape {
	visibility: visible;
}

.status-info-step .sub-heading:only-child {
	text-align: center;
}

.status-info-hold {
	width: 100%;
	height: 4rem;
	overflow: hidden;
	font-size: .75rem;
}

.status-info-hold .info-tooltip {
	margin: -.0625rem 0 -.125rem .5rem;
}

.status-info-hold .status-info-step1 {
	padding-right: .3125rem;
	max-width: 6.25rem;
}

.status-info-hold .status-info-step:not(:first-child) {
	padding-left: 2.1875rem;
}

.activity-list {
	list-style: none;
	padding: .8125rem 0 0;
}

.activity-list:last-of-type {
	margin-bottom: 0;
}

.activity-list:last-of-type li:last-child {
	padding-bottom: 0;
}

.activity-list:last-of-type li:last-child .activity-icon:before {
	visibility: hidden;
}

.activity-list li {
	position: relative;
	padding: .1875rem 4.375rem 1.625rem 2.5rem;
	overflow: hidden;
	font-size: .8125rem;
}

.activity-list .activity-icon {
	position: absolute;
	top: 0;
	left: 0;
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.activity-list .activity-icon:before {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	width: .125rem;
	height: 18.75rem;
}

.activity-list .activity-icon.primary {
	color: #1777ec;
	background: rgba(23, 119, 236, .15);
}

.activity-list .activity-icon.primary:before {
	background: rgba(23, 119, 236, .15);
}

.activity-list .activity-icon.success {
	color: #10c17d;
	background: rgba(16, 193, 125, .15);
}

.activity-list .activity-icon.success:before {
	background: rgba(16, 193, 125, .15);
}

.activity-list .activity-icon.warning {
	color: #ff9a24;
	background: rgba(255, 154, 36, .15);
}

.activity-list .activity-icon.warning:before {
	background: rgba(255, 154, 36, .15);
}

.activity-list .activity-time {
	position: absolute;
	top: 0;
	right: 0;
	font-weight: 500;
	font-size: .75rem;
}

.activity-list .activity-title {
	color: #1777ec;
	margin-bottom: .0625rem;
}

.paging-info .btn,
.pagination-line .btn {
	padding: .625rem .125rem;
	width: 2.5rem;
	height: 2.5rem;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	min-width: 40px;
}

.paging-info .btn-white,
.pagination-line .btn-white {
	color: #6e6f72;
}

.paging-info {
	margin-left: 2rem;
	color: #6e6f72;
}

.paging-info .btn-group {
	margin-left: .5625rem;
}

.paging-info .btn {
	padding: .625rem .125rem;
	width: 2.5rem;
	height: 2.5rem;
	min-width: 40px;
}

.pagination-line .btn {
	padding: .375rem .125rem;
	width: 2rem;
	height: 2rem;
	min-width: 32px;
}

.pagination .btn {
	margin: 0 .25rem;
}

.person-info-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.person-info-list li {
	padding: 1.375rem 1.5rem 1.25rem;
}

.person-info-list li:not(:first-child) {
	border-top: 1px solid #eaedf3;
}

.person-info-list .title {
	margin-bottom: .4375rem;
}

.person-info-list .add-info {
	color: #6e6f72;
}

.person-list {
	font-size: .8125rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 0;
}

.person-list dt {
	color: #6e6f72;
	margin-right: .3125rem;
}

.person-list dd {
	margin: 0 .75rem .375rem 0;
}

.person-info-list .person-list {
	overflow: hidden;
	display: block;
}

.person-info-list .person-list dt {
	float: left;
}

.person-info-list .person-list dd {
	clear: right;
	margin-bottom: 0;
}

.contact-list {
	overflow: hidden;
	display: block;
	color: #6e6f72;
	padding-top: .1875rem;
}

.contact-list dt {
	float: left;
	margin-right: 1.125rem;
	color: #cecfd2;
}

.contact-list dd {
	margin-bottom: .625rem;
}

.contact-list a {
	color: inherit;
}

.modal.show {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.modal .modal-dialog {
	max-width: 42.25rem;
}

.modal .modal-body {
	padding: 1.5rem 2rem;
}

.modal .close {
	width: 2.75rem;
	height: 2.75rem;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: absolute;
	top: .125rem;
	right: .125rem;
	z-index: 100;
	font-size: 2.1875rem;
	line-height: .857;
	color: #cecfd2;
}

.modal .close span {
	outline: none;
}

.modal .form-group {
	position: relative;
}

.modal textarea {
	min-width: 38.25rem;
	height: 10rem;
	min-height: 10rem;
	padding: .5rem .125rem .125rem 1rem;
}

.modal .btn-group {
	margin-right: .8125rem;
}

.modal .action-line {
	position: absolute;
	left: .5rem;
	bottom: .5rem;
}

.modal .action-line .btn {
	padding: .625rem .125rem;
	width: 2.5rem;
	height: 2.5rem;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	min-width: 40px;
}

.modal input[type=submit] {
	margin-left: 1.25rem;
}

.modal-med-history.show {
	display: block !important;
}

.modal-med-history .modal-dialog {
	max-width: 1075px;
}

.modal-med-history .content-holder {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	margin-right: 1px;
	background: #fff;
	border-right: 1px solid #979797;
}

.modal-med-history .modal-header {
	border-color: #979797;
}

.modal-med-history .modal-header .close {
	margin: 0;
}

.modal-med-history .modal-content {
	overflow: hidden;
	border-radius: 0;
	background-color: transparent;
}

.modal-med-history .h1 {
	font-weight: 700;
}

.modal-med-history .direction-right {
	direction: rtl;
	overflow: auto;
	max-height: calc(100vh - 245px);
}

.modal-med-history .direction-left {
	direction: ltr;
}

.modal-med-history .modal-cols-holder {
	padding: 0;
}

.modal-med-history .modal-header {
	min-height: 77px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 0;
	padding: .5rem 1rem .5rem 2.1rem;
}

.modal-med-history .modal-sidebar {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 400px;
	flex: 0 0 400px;
	background: #fff;
	border-left: 1px solid #979797;
}

.modal-cols-holder {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.alerts-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.alerts-list .symbol {
	font-size: 18px;
	font-weight: 700;
	-webkit-transform: translateX(1px);
	-ms-transform: translateX(1px);
	transform: translateX(1px);
}

.alerts-list-item {
	border-bottom: 1px solid #979797;
	padding: .5rem 1.9rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.alerts-list-item svg {
	margin-right: 1rem;
}

.alert-bg-danger {
	fill: rgba(208, 2, 27, .15);
}

.alert-symbol-danger {
	fill: #d0021b;
}

.alert-bg-warning {
	fill: rgba(255, 171, 0, .15);
}

.alert-symbol-warning {
	fill: #ffab00;
}

.documents-status.btn-link {
	padding: 0;
	min-width: 0;
}

input::-ms-clear,
input::-ms-reveal {
	display: none;
}

.custom-switch-right-side {
	padding-right: 3rem;
	padding-left: 0;
	line-height: 1.5rem;
}

.custom-switch-right-side .custom-control-input:checked ~ .custom-control-label::before {
	border-color: #d8dce6;
	background: rgba(216, 220, 230, .1);
}

.custom-switch-right-side .custom-control-input:checked ~ .custom-control-label::after {
	background: #1777ec;
	right: -1.3125rem;
}

.custom-switch-right-side .custom-control-label::before {
	right: -3rem;
	left: auto;
	top: 0;
	height: 1.5rem;
	border-radius: .75rem;
}

.custom-switch-right-side .custom-control-label::after {
	right: -1.625rem;
	left: auto;
	top: .25rem;
}

.custom-select {
	background: #fff url(../images/small-down.svg) no-repeat right .75rem center/11px 11px;
	font-size: .875rem;
	padding: .5625rem 1.75rem .5rem .875rem;
	height: 2.5rem;
}

@media screen and (min-width: 992px) {
	.search-holder .search-form {
		top: 3.75rem;
	}
}

@media screen and (max-width: 767px) {
	.dataTables_wrapper .dataTables_info,
	.dataTables_wrapper .dataTables_paginate {
		float: none;
		text-align: center;
	}

	.dataTables_wrapper .dataTables_paginate {
		margin-top: .5em;
	}
}

@media screen and (max-width: 640px) {
	.dataTables_wrapper .dataTables_length,
	.dataTables_wrapper .dataTables_filter {
		float: none;
		text-align: center;
	}

	.dataTables_wrapper .dataTables_filter {
		margin-top: .5em;
	}
}

div.ql-snow .ql-editor h4 {
	font-size: 1.12525rem;
	margin-bottom: 0.375rem;
}
.btn-sm-tick {
	padding: 0.4rem 0.625rem;
}
.quill.error {
	border: solid 1px red;
}